exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-quicklinks-tsx": () => import("./../../../src/pages/quicklinks.tsx" /* webpackChunkName: "component---src-pages-quicklinks-tsx" */),
  "component---src-pages-quotatious-tsx": () => import("./../../../src/pages/quotatious.tsx" /* webpackChunkName: "component---src-pages-quotatious-tsx" */),
  "component---src-pages-reminderz-tsx": () => import("./../../../src/pages/reminderz.tsx" /* webpackChunkName: "component---src-pages-reminderz-tsx" */),
  "component---src-pages-shortlinks-tsx": () => import("./../../../src/pages/shortlinks.tsx" /* webpackChunkName: "component---src-pages-shortlinks-tsx" */),
  "component---src-pages-teammate-tsx": () => import("./../../../src/pages/teammate.tsx" /* webpackChunkName: "component---src-pages-teammate-tsx" */),
  "component---src-pages-termsofuse-tsx": () => import("./../../../src/pages/termsofuse.tsx" /* webpackChunkName: "component---src-pages-termsofuse-tsx" */)
}

